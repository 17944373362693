import { encode } from 'js-base64';

const LoginUrl =  "https://apijv.code7vr.com";

async function login(email: String, password: String, sessionId: String, tipoBot: String) {

    const response = await fetch(`${LoginUrl}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "auth": encode(`${email}:${password}`),
        "sessionId": sessionId,
        "tipoBot": tipoBot,
      })
    });

    const data = await response.json();
    
    return data;
    
}

export default login